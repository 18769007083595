import styled from 'styled-components';
import { colors } from '../../style';

const Wrapper = styled.div`
    width:100%;
    padding-top:30px;
`

const RowWrapper = styled.div`
    width:100%;
    justify-content:center;
    display:flex;
    padding-left:20px;
    padding-bottom:40px;
    margin-top:30px;
`

const RowView = styled.div`
    width:200px;
    height:200px;
    margin-right:20px;
    a{
        
    }

    img{
        width:100%;
        height:100%;
        object-fit:cover;
    }
`

const Title = styled.h2`
    font-size:40px;
    text-align:center;
    font-weight:bold;
    margin-bottom:30px;
`

const EcTitleImageView = styled.div`
    width:100%;
    display:flex;
    justify-content: center;
    img{
        width:90%;
        max-width:600px;
    }
`

const ECLinkListView = ({project}) => {
    const linkList = project.linkList.filter(row=>row.imageUrl.length > 0 && row.linkUrl.length > 0)
    return <Wrapper>
        {project.hasEcTitleImage && <EcTitleImageView>
            <img src={project.ecTitleImage} />
        </EcTitleImageView>}
        <RowWrapper>
            {linkList.map((data,index) => {
                return <ECLinkView key={index} data={data} />
            })}
        </RowWrapper>
    </Wrapper>
}

const ECLinkView = ({data}) => {
    const {imageUrl, linkUrl} = data

    return <RowView>
        <a href={linkUrl} target="_blank">
            <img src={imageUrl} />
        </a>
    </RowView>
}

export default ECLinkListView