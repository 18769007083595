export const colors = {
    primary: "#16a085",
    secondary: "#34495e",
    accent: "#e67e22",

    primaryLight: "#c6f0c5",

    white: "#ffffff",
    gray: "#aaaaaa",

    border: "#dddddd",
    valid: "#6666ff",
    disable: "#fafafa",
    danger: "#ff6666"
}

export const size = {
    formWidth: 300,
    columnWidth: 800,
    menuWidth: 150,
    headerHeight: 64
}

