import styled from 'styled-components';
import { useEffect } from 'react';
import { projectService } from '../../service/ProjectService';
import {colors} from '../../style';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height:100vh;
  background-color:${colors.primary};
`

const Title = styled.h1`
  color:${colors.white};
  font-size:60px;
  font-weight:bold;
`

const HomeScreen = () => {

  return <Wrapper>
    <Title>VReach</Title>
  </Wrapper>
}

export default HomeScreen