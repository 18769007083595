
class ServiceUtil{
    static post = (url, values) =>{
        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(values)
        })
    }

    static put = (url, values) =>{
        return fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(values)
        })
    }

}

export default ServiceUtil