export class MediaTmp {

    constructor(data) {
        this._data = data
        this.visionList = []
        this.hashTagList = []
    }

    get isTmp(){
        return true
    }

    get id() {
        return this.node.id
    }

    get node() {
        return this._data.node
    }

    get liked() {
        return this.node.edge_liked_by.count
    }

    get commented() {
        return this.node.edge_media_to_comment.count
    }

    get isVideo() {
        return this.node.is_video
    }

    get thumbnail_src() {
        return this.node.thumbnail_src
    }

    get caption() {
        return this.node.accessibility_caption
    }

    get takenAtTimestamp() {
        return this.node.taken_at_timestamp
    }

    get takenAtString() {
        let date = new Date(this.takenAtTimestamp * 1000)
        var year_str = date.getFullYear();
        //月だけ+1すること
        var month_str = 1 + date.getMonth();
        var day_str = date.getDate();
        var hour_str = date.getHours();
        var minute_str = date.getMinutes();
        var second_str = date.getSeconds();
        return `${year_str}/${month_str}/${day_str}`
    }

    get postData(){
        return {
            liked: this.liked,
            commented: this.commented,
            is_video: this.isVideo,
            thumbnail_src: this.thumbnail_src,
            caption: this.caption || "",
            taken_at: parseInt(this.takenAtTimestamp * 1000) / 1000,
            ig_id: this.id,
            vision_list: JSON.stringify(this.visionList)
        }
    }
}

// -----------------------------------

class Media {

    constructor(data) {
        this._data = data
        try{
            this.visionList = JSON.parse(data.vision_list)
            if(!Array.isArray(this.visionList)){
                this.visionList = []
            }
        }catch(e){
            this.visionList = []
        }

        const tagList = this.caption.match(/[#＃][Ａ-Ｚａ-ｚA-Za-z一-鿆0-9０-９ぁ-ヶｦ-ﾟー._-]+/gm);
        this.hashTagList = [...new Set(tagList)]; 
        this.tagList = this.hashTagList.map(row=>row.replace("#", ""))
    }

    get isTmp(){
        return false
    }

    get id() {
        return this._data.id
    }

    get igId(){
        return this._data.ig_id
    }

    get liked() {
        return this._data.liked
    }

    get commented() {
        return this._data.commented
    }

    get isVideo() {
        return this._data.is_video
    }

    get thumbnail_src() {
        return `https://vreach-lp.s3-ap-northeast-1.amazonaws.com/medias/${this.igId}.png`
    }
    

    get caption() {
        return this._data.caption
    }

    get takenAtTimestamp() {
        return this._data.taken_at
    }

    get influencerId(){
        return this._data.influencer_id
    }

    hasTag(tagList){
        const currentTagList = this.tagList
        for(let i=0,max=tagList.length;i<max;i++){
            const tag = tagList[i]
            if(currentTagList.find(row=>row === tag)){
                return true
            }
        }

        return false
    }

    get takenAtString() {
        let date = new Date(this.takenAtTimestamp)
        var year_str = date.getFullYear();
        //月だけ+1すること
        var month_str = 1 + date.getMonth();
        var day_str = date.getDate();
        var hour_str = date.getHours();
        var minute_str = date.getMinutes();
        var second_str = date.getSeconds();
        return `${year_str}/${month_str}/${day_str}`
    }

    get postData(){
        return {
            liked: this.liked,
            commented: this.commented,
            is_video: this.isVideo,
            thumbnail_src: this.thumbnail_src,
            caption: this.caption,
            taken_at: parseInt(this.takenAtTimestamp * 1000) / 1000,
            ig_id: this.id,
            vision_list: JSON.stringify(this.visionList)
        }
    }
}

export default Media