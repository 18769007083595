import styled from 'styled-components';
import TagMediaListView from '../../components/TagMediaListView';

const Wrapper = styled.div`

`

const MediaListView = ({mediaList}) => {

    return <Wrapper>
        <TagMediaListView mediaList={mediaList} />
    </Wrapper>
}

export default MediaListView