const apiRoot = process.env.REACT_APP_API_ROOT

const Config = {
    apiRoot: apiRoot
}

export const API = {
    resources:{
        influencer: `${apiRoot}/influencer`,
        client: `${apiRoot}/client`,
        media: `${apiRoot}/media`,
        project: `${apiRoot}/project`,
        projectInfluencer: `${apiRoot}/project_influencer`,
        tag: `${apiRoot}/tag`,
    }
}

export default Config