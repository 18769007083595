import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { projectService } from '../../service/ProjectService';
import { useParams } from 'react-router';
import { mediaService } from '../../service/MediaService';
import {colors} from "../../style"
import MediaListView from './MediaListView';
import ECLinkListView from './ECLinkListView';

const Wrapper = styled.div`

`

const ProjectInfoView = styled.div`

`

const ProjectTitleView = styled.div`
    width:100%;
    display:flex;
    justify-content: center;
`

const ProjectTitle = styled.div`
    width:90%;
    max-width:600px;
    font-size:32px;
    text-align:center;
    font-weight:600;
    margin-top:40px;

`

const ProjectHashTagListView = styled.ul`
    display:flex;
    justify-content:center;
    width:100%;
    padding-left:10px;
    margin-top:20px;
`

const ProjectHashTag = styled.li`
    margin-right:10px;
    a{
        color:${colors.primary};
        font-size:20px;
        :hover{
            text-decoration:underline;
        }
    }
`

const TitleImageView = styled.div`
    width:100%;
    display:flex;
    margin-top:40px;
    justify-content: center;
    img{
        width:90%;
        max-width:600px;
    }
`

const DescriptionView = styled.div`
    width:100%;
    display:flex;
    justify-content: center;
`

const DescriptionLabel = styled.h3`
    width:90%;
    max-width:600px;
    font-size:20px;
    text-align:center;
    margin-top:30px;
`

const YoutubeView = styled.div`
    width:100%;
    display:flex;
    justify-content: center;
    margin-top:40px;
`

const YoutubeFrame = styled.iframe`
    width:90%;
    max-width:600px;

    height:400px;
    max-height:400px;
`

class LpScreenModel{
    constructor(
        projectname,
        [project, setProject],
        [mediaList, setMediaList]
    ){
        this.projectname = projectname
        this.project = project
        this.setProject = setProject
        this.mediaList = mediaList
        this.setMediaList = setMediaList
    }

    onAppear = () => {
        projectService.fetch(this.projectname).then(project=>{
            console.log("project", project)
            this.setProject(project)
            return mediaService.fetchWithProjectId(project.id)
        }).then((results)=>{
            console.log(results)
            const [mediaList, influencerList] = results
            mediaList.forEach(media => {
                media.influencer = influencerList.find(i=>i.id === media.influencerId)
            });
            this.setMediaList(mediaList)
        })
        .catch(e=>{
            console.log(e)
        })
    }
}

const LpScreen = () => {

    const { projectname } = useParams()
    const viewModel = new LpScreenModel(projectname, useState(undefined), useState(undefined))
    const {project, mediaList} = viewModel

    useEffect(viewModel.onAppear, [])

    if(!project || !mediaList){
        return <p>Loading</p>
    }

    return <Wrapper>
        <ProjectInfoView>
            {project.hasTitleImage && <TitleImageView>
                <img src={project.titleImage} />
            </TitleImageView>}
            {project.hasYoutube && <YoutubeView>
                <YoutubeFrame
                    src={project.youtubeUrl} 
                    frameborder="0" 
                    allow="autoplay; encrypted-media" 
                    allowfullscreen
                />
            </YoutubeView>}
            <ProjectTitleView><ProjectTitle>{project.lpTitle}</ProjectTitle></ProjectTitleView>
            {project.hasDescription && <DescriptionView>
                <DescriptionLabel>
                    {project.description}
                </DescriptionLabel>
            </DescriptionView>}
            <ProjectHashTagListView>
                {project.tagList.map(tag=><ProjectHashTag key={tag}>
                    <a href={"https://www.instagram.com/explore/tags/" + tag} target="_blank">{tag}</a></ProjectHashTag>)}
            </ProjectHashTagListView>
            <MediaListView mediaList={mediaList} />
            <ECLinkListView project={project} />
        </ProjectInfoView>
    </Wrapper>
}

export default LpScreen