import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import TagMediaView from './TagMediaView';

const Wrapper = styled.div`
    flex:1;
    padding:30px;
    height:100%;
    overflow-y:scroll;
`

const MediaListWrapper = styled.div`
    display:grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    @media screen and (max-width:1200px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media screen and (max-width:800px) {
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media screen and (max-width:640px) {
        grid-template-columns: 1fr 1fr;
    }
`

const TagMediaListView = ({mediaList}) => {

    return <Wrapper>
        {/* <ui.PageTitle>「{tagString}」を含む投稿 <span>({mediaList.length} 件)</span></ui.PageTitle> */}
        <MediaListWrapper>
            {mediaList.map(media=><TagMediaView media={media} key={media.id} />)}
        </MediaListWrapper>
    </Wrapper>
}


export default TagMediaListView