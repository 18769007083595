import { API } from '../Config'
import ServiceUtil from "./ServiceUtil";
import Project from "../entities/Project";

class ProjectService {

    fetch = (name) => {
        return fetch(API.resources.project + "/" + name).then((response) => {
            return response.json();
        }).then(json => {
            if(!json){
                throw new Error("project not found")
            }
            return new Project(json)
        })
    }
}

export const projectService = new ProjectService()
