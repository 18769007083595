import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { colors, size } from '../style'
import util from '../util';

const Wrapper = styled.div`
    padding:1px;
    margin-bottom:20px;
`

const MediaImage = styled.img`
    width:100%;
    object-fit:cover;
`

const InfoView = styled.div`
    margin-top:10px;
`

const UserInfoView = styled.div`
    display:flex;
    width:100%;
    align-items:center;
`

const UserTextView = styled.div`
    flex:1;
    h2{
        font-size:20px;
        font-weight:bold;
        span{
            font-size:14px;
            font-weight:normal;
        }
    }

    h3{
        margin-top:5px;
        font-size:14px;
        a{
            color:${colors.primary};
            :hover{
                text-decoration:underline;
            }
        }
    }
`

const ProfileImage = styled.div`
    width:50px;
    height:50px;
    border-radius:25px;
    border:2px solid ${colors.primary};
    overflow:hidden;
    margin-right:10px;
    img{
        width:100%;
        height:100%;
        object-fit:cover;
    }
`

class TagMediaViewModel{
    constructor(appState, media){
        this.appState = appState
        this.media = media
    }

    get src(){
        return this.media.thumbnail_src
    }

    get influencer(){
        
        // console.log(this.media.influencer)
        return this.media.influencer
        return {}
        // return this.appState.influencerList.find(row=>row.id === this.media.influencerId)
    }

    get name(){
        return this.influencer.fullName
    }

    get username(){
        return this.influencer.username
    }

    get profilePic(){
        return this.influencer.profilePic
    }

    get liked(){
        return util.keta(this.media.liked)
    }
}


const TagMediaView = ({media}) => {

    const viewModel = new TagMediaViewModel({}, media)
    const { src, name, profilePic, liked , username} = viewModel

    return <Wrapper>
        <MediaImage src={src} />
        <InfoView>
            <UserInfoView>
                <ProfileImage>
                    <img src={profilePic} />
                </ProfileImage>
                <UserTextView>
                    <h2>{liked} <span>いいね</span></h2>
                    <h3><a href={`https://www.instagram.com/${username}/`} target="_blank">{name}</a></h3>
                </UserTextView>
            </UserInfoView>
        </InfoView>
    </Wrapper>
}

export default TagMediaView