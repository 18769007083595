import Media, { MediaTmp } from "./Media"

export class InfluencerTmp {

    constructor(sharedData) {
        this._sharedData = sharedData
        this.timeLineMediaList = this.user.edge_owner_to_timeline_media.edges.slice().map(row => new MediaTmp(row))
    }

    get id() {
        return this.user.id
    }

    get user() {
        return this._sharedData.entry_data.ProfilePage[0].graphql.user
    }

    get follow() {
        return this.user.edge_follow.count
    }

    get follower() {
        return this.user.edge_followed_by.count
    }

    get fullName() {
        return this.user.full_name
    }

    get biography() {
        return this.user.biography
    }

    get profilePic() {
        return this.user.profile_pic_url
    }

    get username() {
        return this.user.username
    }

    get allMediaCount() {
        return this.user.edge_owner_to_timeline_media.count
    }

    get avarageLiked() {

        let total = this.timeLineMediaList.map(row => row.liked).reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        });

        return parseInt(total / this.timeLineMediaList.length)
    }

    get avarageCommented() {

        let total = this.timeLineMediaList.map(row => row.commented).reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        });

        return parseInt(total / this.timeLineMediaList.length)
    }

    get visionList() {
        if (!this.timeLineMediaList) { return [] }

        let results = []
        this.timeLineMediaList.forEach(media => {
            if(!media.visionList){ return }
            media.visionList.forEach(vision => {
                let current = results.find(row => {
                    return row.mid == vision.mid
                })
                if (current) {
                    current.count = current.count + 1
                } else {
                    results.push({
                        count: 1,
                        mid: vision.mid,
                        description: vision.description,
                        score: vision.score
                    })
                }
            })
        });

        return results.sort((a, b) => {
            if (b.count > a.count) { return 1 }
            if (a.count > b.count) { return -1 }
            return 0
        })
    }

    get postData(){
        return {
            name: this.name,
            post_count: this.allMediaCount,
            follow_count: this.follow,
            follower_count: this.follower,
            ig_id: this.id,
            username: this.username,
            fullname: this.fullName,
            biography: this.biography,
            profile_pic_url: this.profilePic
        }
    }
}

class Influencer {

    constructor(data) {
        this._data = data
        this.timeLineMediaList = []
    }

    get id() {
        return this._data.id
    }

    get igId(){
        return this._data.ig_id
    }

    get follow() {
        return this._data.follow_count
    }

    get follower() {
        return this._data.follower_count
    }

    get fullName() {
        return this._data.fullname
    }

    get biography() {
        return this._data.biography
    }

    get profilePic() {
        return `https://vreach-lp.s3-ap-northeast-1.amazonaws.com/influencers/${this.igId}.png`
        // return this._data.profile_pic_url
    }

    get username() {
        return this._data.username
    }

    get allMediaCount() {
        return this._data.post_count
    }

    get avarageLiked() {

        let total = this.timeLineMediaList.map(row => row.liked).reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        });

        return parseInt(total / this.timeLineMediaList.length)
    }

    get avarageCommented() {

        let total = this.timeLineMediaList.map(row => row.commented).reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        });

        return parseInt(total / this.timeLineMediaList.length)
    }

    get visionList() {
        if (!this.timeLineMediaList) { return [] }

        let results = []
        this.timeLineMediaList.forEach(media => {
            media.visionList.forEach(vision => {
                let current = results.find(row => {
                    return row.mid == vision.mid
                })
                if (current) {
                    current.count = current.count + 1
                } else {
                    results.push({
                        count: 1,
                        mid: vision.mid,
                        description: vision.description,
                        score: vision.score
                    })
                }
            })
        });

        return results.sort((a, b) => {
            if (b.count > a.count) { return 1 }
            if (a.count > b.count) { return -1 }
            return 0
        })
    }

    get postData(){
        return {
            name: this.name,
            post_count: this.allMediaCount,
            follow_count: this.follow,
            follower_count: this.follower,
            ig_id: this.id,
            username: this.username,
            fullname: this.fullName,
            biography: this.biography
        }
    }

}

export default Influencer