import Media from "../entities/Media";
import Influencer from "../entities/Influencer";
import ServiceUtil from "./ServiceUtil";
import { API } from "../Config";

class MediaService {

    fetchVision = (base64Data) => {
        var KEY = 'AIzaSyCRQDN-TYQ4X60HYVpSesF7Ebacjlb7g9Y'
        var url = 'https://vision.googleapis.com/v1/images:annotate?key='
        var apiUrl = url + KEY

        const planeBase64 = base64Data.split(",")[1]

        const requestData = {
            "requests": [
                {
                    "image": {
                        "content": planeBase64
                    },
                    "features": [
                        {
                            "maxResults": 10,
                            "type": "LABEL_DETECTION"
                        }
                    ]
                }
            ]
        }

        return ServiceUtil.post(apiUrl, requestData).then(res => {
            return res.json()
        }).then(json => {
            return json.responses[0].labelAnnotations
        })
    }

    fetchVisionWithUrl = (url) => {
        console.log("url", url)
        var KEY = 'AIzaSyCRQDN-TYQ4X60HYVpSesF7Ebacjlb7g9Y'
        var url = 'https://vision.googleapis.com/v1/images:annotate?key='
        var apiUrl = url + KEY

        const requestData = {
            "requests": [
                {
                    "image": {
                        "source": {
                            "imageUri": url
                        }
                    },
                    "features": [
                        {
                            "maxResults": 10,
                            "type": "LABEL_DETECTION"
                        }
                    ]
                }
            ]
        }

        return ServiceUtil.post(apiUrl, requestData).then(res => {
            return res.json()
        }).then(json => {
            return json.responses[0].labelAnnotations
        })
    }

    create = (media, influencerId) => {
        let data =  media.postData
        data.influencer_id = influencerId
        return ServiceUtil.post(API.resources.media, data).then(res=>res.json())
    }

    fetchList = () =>{
        return fetch(API.resources.media).then((response)=>{
            return response.json();
        }).then(json=>{
            return json.map(row=>new Media(row))
        })
    }

    fetchWithProjectId = (projectId) => {
        return fetch(API.resources.media + "/" + projectId).then((response)=>{
            return response.json();
        }).then(json=>{
            // console.log("media", json)
            console.log(json)
            const mediaList = json.media_list.map(row=>new Media(row))
            const incluencerList = json.influencer_list.map(row=> new Influencer(row))
            return [mediaList, incluencerList]
        })
    }

}

const mediaService = new MediaService()

export { mediaService }

