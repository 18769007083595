import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import { useEffect } from 'react';
import { projectService } from './service/ProjectService';
import { Route, Router, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import LpScreen from './screen/lp/LpScreen';
import HomeScreen from './screen/home/HomeScreen'

const Wrapper = styled.div`

`

function App() {
  return <BrowserRouter>
    <Switch>
      <Route path='/:projectname' component={LpScreen} />
      <Route path="/" component={HomeScreen} />
    </Switch>
  </BrowserRouter>
}

export default App;
